/* jshint esversion: 6 */

// Modules imported by Webpack (config/webpack.config.js)
// jQuery
// Utils (./imports/utils.js)

// Bootstrap
// https://www.npmjs.com/package/bootstrap-sass
import 'bootstrap';

// svg4everybody
// https://www.npmjs.com/package/svg4everybody
import svg4everybody from 'svg4everybody';
svg4everybody({ nosvg: false });

// Declare a window global variable, for common utils
window.commonUtils = {
	themeLink: '',
	videosMuted: true,
}

// Import modules
jQuery('[data-module]').each(function(){
	let $element = jQuery(this), name = $element.attr('data-module');
	import(/* webpackChunkName: "[request]" */ './modules/' + name).then(function({ default: Module }){ new Module($element); });
});
